import React from 'react'

import SEO from '~components/shared/SEO'
import HomeHeader from '~components/home/home-header'
import WhatWeDo from '~components/home/what-we-do'
import WhoWeAre from '~components/home/who-we-are'
import OurClients from '~components/home/our-clients'
import ContactUs from '~components/home/contact'

export default function HomePage() {
    return (
        <>
            <SEO />
            <HomeHeader />
            <WhatWeDo />
            <WhoWeAre />
            <OurClients />
            <ContactUs />
        </>
    )
}
