import React from 'react'
import { navigate } from 'gatsby'
import {
    Box,
    Flex,
    Heading,
    Text,
    Button,
    useBreakpointValue,
} from '@chakra-ui/react'

import CreateMotionComponent, {
    FadeUpBox,
} from '~components/shared/custom-animation'

import circuits from '~images/backgrounds/circuits.svg'

const MotionFlex = CreateMotionComponent(Flex)
const MotionBox = CreateMotionComponent(Box)
const MotionText = CreateMotionComponent(Text)

const leftBox = { hover: { x: 15 } }
const rightBox = { hover: { x: -15 } }
const number = {
    hover: { color: '#f74734', transition: 'color 0s ease-out 0.5s' },
}

const WhatWeDo = () => (
    <Box bg="gray.50">
        <Box
            bgImage={`url(${circuits})`}
            bgSize="cover"
            bgPosition="center"
            bgRepeat="no-repeat"
        >
            <Flex px="10%" flexDir="column" align="center">
                <Heading fontSize={['sm', 'md']} mb={[2, 5]}>
                    What we do
                </Heading>
                <Box py={{ base: 2, md: 0 }} w={{ md: '75%' }}>
                    <Heading
                        fontSize={['lg', 'xl']}
                        lineHeight={['45px', '60px']}
                        textAlign="center"
                    >
                        With you every step of the way.
                    </Heading>
                </Box>
                <Box w={{ sm: '100%', md: '90%' }} mt={3}>
                    <Text
                        fontSize={['sm', 'md']}
                        textAlign="center"
                        color="gray.500"
                    >
                        Breach is a web and app development firm that helps
                        ambitious businesses bring their website or app vision
                        to life and work to see it through to the end. From the
                        design process to development to deployment, we want to
                        work with you through it all to make something that is
                        completely{' '}
                        <Box as="span" fontStyle="italic" fontWeight="700">
                            you
                        </Box>
                        .
                    </Text>
                </Box>
            </Flex>
            <Flex w="100%" justify="center">
                <Flex direction="column" align="center">
                    <Box w={{ base: '90%', md: '80%' }}>
                        <FadeUpBox>
                            <MotionFlex
                                mt={[38, 75]}
                                mb={[2, 25]}
                                pr={{ md: '20%' }}
                                justify="space-between"
                                whileHover="hover"
                            >
                                <Box my={25}>
                                    <MotionText
                                        fontSize={['sm', 'md']}
                                        color={{
                                            base: 'brand.400',
                                            md: 'gray.400',
                                        }}
                                        variants={number}
                                        pr={{ base: 7, md: 0 }}
                                    >
                                        one_
                                    </MotionText>
                                </Box>
                                <MotionBox w="80%" py={25} variants={leftBox}>
                                    <Heading fontSize={['md', 'lg']} mb={2}>
                                        Design
                                    </Heading>
                                    <Text
                                        fontSize={['xs', 'sm']}
                                        color="gray.600"
                                    >
                                        Before development even starts, we want
                                        to understand your vision in every
                                        aspect. We'll help you produce the
                                        perfect mock-up, free of charge.
                                    </Text>
                                </MotionBox>
                            </MotionFlex>
                        </FadeUpBox>
                    </Box>
                    <Box w={{ base: '90%', md: '80%' }}>
                        <FadeUpBox>
                            <MotionFlex
                                my={[2, 25]}
                                pl={{ md: '20%' }}
                                justify="space-between"
                                whileHover="hover"
                                direction={{ base: 'row-reverse', md: 'row' }}
                            >
                                <MotionBox w="80%" py={25} variants={rightBox}>
                                    <Heading
                                        fontSize={['md', 'lg']}
                                        textAlign={{ md: 'right' }}
                                        mb={2}
                                    >
                                        Development
                                    </Heading>
                                    <Text
                                        fontSize={['xs', 'sm']}
                                        textAlign={{ md: 'right' }}
                                        color="gray.600"
                                    >
                                        Once you're in love with a design, we'll
                                        develop with you collaboratively,
                                        iterating on each version of your site
                                        or app taking feedback along the way.
                                    </Text>
                                </MotionBox>
                                <Box my={25}>
                                    <MotionText
                                        fontSize={['sm', 'md']}
                                        textAlign="right"
                                        color={{
                                            base: 'brand.400',
                                            md: 'gray.400',
                                        }}
                                        variants={number}
                                        pr={{ base: 7, md: 0 }}
                                    >
                                        two_
                                    </MotionText>
                                </Box>
                            </MotionFlex>
                        </FadeUpBox>
                    </Box>
                    <Box w={{ base: '90%', md: '80%' }}>
                        <FadeUpBox>
                            <MotionFlex
                                my={[2, 25]}
                                pr={{ md: '20%' }}
                                justify="space-between"
                                whileHover="hover"
                            >
                                <Box my={25}>
                                    <MotionText
                                        fontSize={['sm', 'md']}
                                        color={{
                                            base: 'brand.400',
                                            md: 'gray.400',
                                        }}
                                        variants={number}
                                        pr={{ base: 3, md: 0 }}
                                    >
                                        three_
                                    </MotionText>
                                </Box>
                                <MotionBox w="80%" py={25} variants={leftBox}>
                                    <Heading fontSize={['md', 'lg']} mb={3}>
                                        Deployment
                                    </Heading>
                                    <Text
                                        fontSize={['xs', 'sm']}
                                        color="gray.600"
                                    >
                                        Once the app is complete, you can sit
                                        back while we handle getting it
                                        launched. AWS and Google Cloud are our
                                        specialties.
                                    </Text>
                                </MotionBox>
                            </MotionFlex>
                        </FadeUpBox>
                    </Box>
                    <Box w={{ base: '90%', md: '80%' }}>
                        <FadeUpBox>
                            <MotionFlex
                                mb={[38, 75]}
                                mt={[2, 25]}
                                pl={{ md: '20%' }}
                                justify="space-between"
                                whileHover="hover"
                                direction={{ base: 'row-reverse', md: 'row' }}
                            >
                                <MotionBox w="80%" py={25} variants={rightBox}>
                                    <Heading
                                        fontSize={['md', 'lg']}
                                        textAlign={{ md: 'right' }}
                                        mb={3}
                                    >
                                        Maintenance
                                    </Heading>
                                    <Text
                                        fontSize={['xs', 'sm']}
                                        textAlign={{ md: 'right' }}
                                        color="gray.600"
                                    >
                                        We're proud of what we help create and
                                        want to see it succeed. Long-term
                                        supports ensures your site never seems
                                        neglected.
                                    </Text>
                                </MotionBox>
                                <Box my={25}>
                                    <MotionText
                                        fontSize={['sm', 'md']}
                                        textAlign="right"
                                        color={{
                                            base: 'brand.400',
                                            md: 'gray.400',
                                        }}
                                        variants={number}
                                        pr={{ base: 6, md: 0 }}
                                    >
                                        four_
                                    </MotionText>
                                </Box>
                            </MotionFlex>
                        </FadeUpBox>
                    </Box>
                    <FadeUpBox>
                        <Flex w="100%" justify="center">
                            <Button
                                onClick={() => navigate('/services')}
                                variant="brand"
                            >
                                See our process ➞
                            </Button>
                        </Flex>
                    </FadeUpBox>
                </Flex>
            </Flex>
        </Box>
    </Box>
)

const ServiceStep = ({ number, title, description, isRight }) => (
    <MotionFlex
        w="80%"
        mt={75}
        mb={25}
        pr="20%"
        justify="space-between"
        whileHover="hover"
    >
        {!isRight && (
            <Box my={25}>
                <MotionText fontSize="md" color="gray.200" variants={number}>
                    one_
                </MotionText>
            </Box>
        )}
        <MotionBox w="80%" py={25} variants={leftBox}>
            <Heading fontSize="lg" mb={2}>
                Design
            </Heading>
            <Text fontSize="sm">
                We'll help you expand your idea and make it as profitable as
                possible.
            </Text>
        </MotionBox>
        {isRight && (
            <Box my={25}>
                <MotionText fontSize="md" color="gray.200" variants={number}>
                    one_
                </MotionText>
            </Box>
        )}
    </MotionFlex>
)

export default WhatWeDo
