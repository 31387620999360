import React from 'react'
import { navigate } from 'gatsby'
import { Flex, Heading, Button, Image } from '@chakra-ui/react'

import bottomCircuits from '~images/backgrounds/bottom_circuits.svg'

const ContactUs = () => (
    <Flex bg="gray.50" h="40vh" justify="center" align="center">
        <Flex
            px="10%"
            direction="column"
            justify="space-around"
            align="center"
            h="60%"
        >
            <Heading fontSize={{ base: 'lg', md: '2xl' }}>
                Let's make something great.
            </Heading>
            <Flex w="100%" py={{ base: 4, md: 0 }} justify={{ md: 'center' }}>
                <Button onClick={() => navigate('/contact')} variant="brand">
                    Get an estimate (in under 48 hours!)
                </Button>
            </Flex>
        </Flex>
    </Flex>
)

export default ContactUs
