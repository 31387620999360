import React from 'react'
import { navigate } from 'gatsby'
import { Flex, Box, Heading, Text, Button, Image } from '@chakra-ui/react'

import CreateMotionComponent, {
    FadeUpBox,
} from '~components/shared/custom-animation'

import mbLogo from '~images/logos/MB_Logo.png'
import mentrU from '~images/logos/mentr_icon.png'
import dhLabs from '~images/logos/dh_labs.png'

const darkHorse = {
    hover: {
        filter: 'brightness(0)',
    },
}

const others = {
    hover: {
        filter: 'grayscale(0)',
    },
}

const OurClients = () => (
    <>
        <Flex px="10%" flexDir="column" align="center" mb={[50, 100]}>
            <FadeUpBox>
                <Heading fontSize={['sm', 'md']} mb={5}>
                    Our Clients
                </Heading>
            </FadeUpBox>
            <Box w={{ md: '75%' }} py={0}>
                <FadeUpBox>
                    <Heading
                        fontSize={['lg', 'xl']}
                        lineHeight={['45px', '60px']}
                        textAlign="center"
                    >
                        Don't take it from us, hear from our clients.
                    </Heading>
                </FadeUpBox>
            </Box>
            <Box w={{ md: '70%' }} mt={3}>
                <FadeUpBox>
                    <Text
                        fontSize={['sm', 'md']}
                        textAlign="center"
                        color="gray.500"
                    >
                        We’ve worked with some amazing people, including Baton
                        Rouge locals, helping to make the best possible product
                        we could for them.
                    </Text>
                </FadeUpBox>
            </Box>
        </Flex>
        <Flex justify="center" mb={[30, 50]}>
            <Box w={{ md: '40%' }}>
                <FadeUpBox>
                    <Flex
                        justify={{ base: 'space-evenly', md: 'space-between' }}
                        align="flex-end"
                    >
                        <ClientColumn
                            source={mbLogo}
                            title="Mallard Bay"
                            description='"Very good service. Would hire again!"'
                            variants={others}
                            filter="grayscale(100%)"
                        />
                        <ClientColumn
                            source={mentrU}
                            title="LS-imPACt"
                            description='"Awesome application. Great service!"'
                            variants={others}
                            filter="grayscale(100%)"
                        />
                        <ClientColumn
                            source={dhLabs}
                            title="Dark Horse Labs"
                            description='"Awesome application. Great service!"'
                            variants={darkHorse}
                            filter="brightness(0.3)"
                        />
                    </Flex>
                </FadeUpBox>
            </Box>
        </Flex>
        <Flex w="100%" justify="center" mb={100}>
            <FadeUpBox>
                <Button onClick={() => navigate('/projects')} variant="brand">
                    View projects ➞
                </Button>
            </FadeUpBox>
        </Flex>
    </>
)

const ClientColumn = ({ source, variants, filter }) => {
    const MotionFlex = CreateMotionComponent(Flex)
    const MotionImage = CreateMotionComponent(Image)

    return (
        <Flex
            direction="column"
            justify="flex-end"
            align="flex"
            w="30%"
            h="100%"
        >
            <MotionFlex
                w="100%"
                h="100%"
                mb={50}
                justify="center"
                align="flex-end"
                whileHover="hover"
            >
                <MotionImage
                    src={source}
                    w="45%"
                    filter={filter}
                    variants={variants}
                />
            </MotionFlex>
        </Flex>
    )
}

export default OurClients
