import React from 'react'
import { navigate } from 'gatsby'
import { Flex, Box, Heading, Text, Image, Button } from '@chakra-ui/react'

import CreateMotionComponent, {
    FadeUpBox,
    FadeIn,
    Slide,
} from '~components/shared/custom-animation'

import slantBottom from '~images/backgrounds/gray_slant_bottom.svg'
import oval from '~images/backgrounds/oval.svg'
import mattLaugh from '~images/staff/Matt_Laugh.jpg'
import theTeam from '~images/staff/The_Team.jpg'

const MotionBox = CreateMotionComponent(Box)
const MotionImage = CreateMotionComponent(Image)

const image = { hover: { x: '15px', y: '-15px' } }
const box = { hover: { x: '-15px', y: '15px' } }

const WhoWeAre = () => (
    <>
        <Flex
            direction="column"
            align="center"
            bgImage={`url(${slantBottom})`}
            bgPosition="top"
            bgSize="cover"
            bgRepeat="no-repeat"
            pt={{ base: 150, md: 300 }}
        >
            <FadeUpBox>
                <Heading fontSize={['sm', 'md']} mb={5}>
                    Why choose us
                </Heading>
            </FadeUpBox>
            <Box w={{ base: '90%', md: '75%' }}>
                <FadeUpBox>
                    <Heading
                        fontSize={['lg', 'xl']}
                        lineHeight={['40px', '60px']}
                        textAlign="center"
                    >
                        Centered on You
                    </Heading>
                </FadeUpBox>
            </Box>
            <Box w={{ base: '90%', md: '70%' }} mt={3}>
                <FadeUpBox>
                    <Text
                        fontSize={['sm', 'md']}
                        textAlign="center"
                        color="gray.500"
                    >
                        What's so great about us? We bring more than amazing
                        development to the table. At our core, our philosophy
                        includes you. We guarantee satisfaction, and we will
                        collaborate with you more than any other firm in order
                        to bring your product to life. See below for more
                        reasons to choose us.
                    </Text>
                </FadeUpBox>
            </Box>
        </Flex>
        <Flex
            px={{ md: '10%' }}
            direction="column"
            mb={100}
            bgImage={`url(${oval})`}
            bgPosition="center"
            bgSize="contain"
            bgRepeat="no-repeat"
        >
            <Flex
                w="100%"
                justify="space-between"
                direction={{ base: 'column', md: 'row' }}
                my={100}
            >
                <Flex w={{ md: '50%' }} justify="center">
                    <ResponsiveImage source={mattLaugh} />
                </Flex>
                <Flex
                    w={{ md: '50%' }}
                    direction="column"
                    justify="center"
                    px="5%"
                >
                    <FadeUpBox>
                        <Heading
                            py={{ base: 4, md: 0 }}
                            fontSize="lg"
                            lineHeight="45px"
                        >
                            Our Values
                        </Heading>
                        <Text fontSize="sm" mb={8} color="gray.600">
                            The values we share at Breach are what make us who
                            we are. By getting to know our values, you get to
                            know us, how we operate, and why we're doing what
                            we're doing.
                        </Text>
                    </FadeUpBox>
                    <Flex w="100%" justify="flex-start">
                        <FadeUpBox>
                            <Button
                                onClick={() => navigate('/about#values')}
                                variant="brand"
                            >
                                Check out our values ➞
                            </Button>
                        </FadeUpBox>
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                w="100%"
                justify="space-between"
                direction={{ base: 'column-reverse', md: 'row' }}
                my={{ md: 100 }}
            >
                <Flex
                    w={{ base: '100%', md: '50%' }}
                    direction="column"
                    justify="center"
                    px="5%"
                >
                    <FadeUpBox>
                        <Heading
                            py={{ base: 4, md: 0 }}
                            fontSize="lg"
                            lineHeight="45px"
                            textAlign="right"
                        >
                            The Team
                        </Heading>
                        <Text
                            fontSize="sm"
                            textAlign="right"
                            mb={8}
                            color="gray.600"
                        >
                            Our team is composed of some of the best and
                            brightest. We work hard to bring value to every
                            client, and that value begins with a relationship
                            built on trust.
                        </Text>
                    </FadeUpBox>
                    <Flex w="100%" justify="flex-end">
                        <FadeUpBox>
                            <Button
                                onClick={() => navigate('/about#team')}
                                variant="brand"
                            >
                                Get to know us ➞
                            </Button>
                        </FadeUpBox>
                    </Flex>
                </Flex>
                <Flex w={{ md: '50%' }} justify="center">
                    <ResponsiveImage source={theTeam} />
                </Flex>
            </Flex>
        </Flex>
    </>
)

const ResponsiveImage = ({ source }) => (
    <Box w="90%">
        <Slide dur={1.2}>
            <MotionBox
                objectFit="cover"
                w="100%"
                h={['30vh', '45vh']}
                pos="relative"
            >
                {/* <MotionBox
                    pos="absolute"
                    bgGradient="linear(to-br, brand.400, gradient)"
                    w="100%"
                    h="100%"
                    boxShadow="lg"
                    variants={box}
                /> */}
                <MotionImage
                    pos="absolute"
                    h="100%"
                    w="100%"
                    objectFit="cover"
                    src={source}
                    variants={image}
                />
            </MotionBox>
        </Slide>
    </Box>
)

export default WhoWeAre
