import React, { useState } from 'react'
import { Box, Center, Flex, Heading, Text, Image } from '@chakra-ui/react'

import { FadeIn, FadeUpBox } from '~components/shared/custom-animation'

import slant from '~images/backgrounds/gray_slant.svg'
import intro from '~images/home/intro.webm'
import mainLoop from '~images/home/main_loop.webm'
import Globe from '~images/backgrounds/globe.svg'

const HomeHeader = () => {
    // const [introPlayed, setPlayed] = useState(false)

    return (
        <Box
            // w="100vw"
            h={{ base: '70vh', lg: '90vh' }}
            bgImage={`url(${slant})`}
            bgPosition="bottom"
            bgSize="auto"
            bgRepeat="no-repeat"
        >
            <Center w="100%" h="100%" justifyContent="space-between">
                <Flex
                    w="100%"
                    h="70%"
                    px="10%"
                    justify="center"
                    align="center"
                    gridColumnGap="36px"
                >
                    <Flex
                        w={{ base: '100%', md: '45%' }}
                        flexDir="column"
                        alignItems={{ base: 'center', md: 'flex-start' }}
                    >
                        <Box
                            mb={[12, null, 0]}
                            w={64}
                            display={['block', null, 'none']}
                        >
                            <FadeIn>
                                <Image
                                    h="100%"
                                    w="100%"
                                    objectFit="cover"
                                    src={Globe}
                                />
                            </FadeIn>
                        </Box>
                        <FadeUpBox>
                            <Heading
                                fontSize={['lg', null, 'xl', '2xl']}
                                mb={2}
                                textAlign={{ base: 'center', md: 'left' }}
                            >
                                Breach Your Industry
                            </Heading>
                        </FadeUpBox>
                        <FadeUpBox>
                            <Text
                                fontSize={['sm', null, 'md']}
                                textAlign={{ base: 'center', md: 'left' }}
                                color={{ base: 'black', md: 'gray.500' }}
                            >
                                Our web and app team delivers unbelievable
                                quality at an equally unbelievable price.
                            </Text>
                        </FadeUpBox>
                    </Flex>
                    <Box
                        w={{ md: 72, lg: 96 }}
                        display={{ base: 'none', md: 'block' }}
                    >
                        <FadeIn>
                            <Image
                                h="100%"
                                w="100%"
                                objectFit="cover"
                                src={Globe}
                            />
                        </FadeIn>
                    </Box>
                </Flex>
            </Center>
        </Box>
    )
}

export default HomeHeader
